body{
    background-color: #222222;
    font-family: Helvetica;
    color: white;
    margin: 0;
}

.containerStyle-tracks{
        text-align: center;
      }

.nucalm-img-tracks{
    width:25%;
    padding-top: 55px;
    padding-bottom: 10px;
    display: block;
    margin: auto;
    margin-bottom: 20px;

}

.feedback-subtext{
    font-weight: 300;
    width: 55%;
    font-size: 16px;
    margin: auto;
}

.subHero-tracks{
    font-weight: 300;
    font-size: 18px;
    /* width: 88%; */
    margin-left: 75px;
    margin-right: 4rem;
    line-height: 1.5;
    text-align: left;

}

.hero-tracks{
    font-weight: 700;
    letter-spacing: 1px;
    text-align: left;
    margin-left: 75px;
    font-size: 45px;
    margin-top: 1rem;
    margin-bottom: 0;
}

.nucalm-video{
    width: 50%;
    height: 23rem;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}

.nucalm_screens{
width: 100%;
}

.nucalm-hover-box{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 75%;
    height: 5.5rem;
    margin: auto;}

.nucalm_screens_container{
    width: 100%;
    height: 100%;
    position: relative;
}

.nucalm_screen_container{
    height: 37rem;
    width: 17.1rem;
    margin-top: 20px;
    margin-right: 3rem;
    margin-bottom: 2rem;
    position: relative;
    display: inline-block;
}

.hover-number{
    position: absolute;
    z-index: 2;
    font-weight: 600;
    font-size: 24px;
    color: gold;
}

/* .daily-message{
    top: 19%;
    left: 8%;
} */



.track_categories{
    height: 20rem;
    width: 40rem;
    margin-right: 20rem;
    object-fit: cover;
    object-position: 10% 20%;
}
.beta_tracks{
    height: 300px;
    object-fit: cover;
    width: 300px;
    border-radius: 50px;
    filter: opacity(0.6) saturate(150%);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.subtext-tracks{
    font-weight: 300;
    font-size: 18px;
    text-align: left;
    margin-left: 75px;
    margin-right: 3rem;
}

.textoverlay_container{
    width: 300px;
    height: 300px;
    position: relative;
    /* background-color: white; */
    display: inline-block;
    border-radius: 50px;
    margin: 50px;
}

.textoverlay{
    position: absolute;
    top: 4%;
    left: 8%;
    font-weight: 600;
    width: 85%;
    font-size: 40px;
    letter-spacing: 2px;
}
/* 
.textoverlay_container:hover .beta_tracks{
    filter: opacity(0.3) saturate(150%);
} */
.feedbacksubtext{
    font-weight: 300;
    width: 85%;
    font-size: 16px;
    margin: auto;
}

.hover-bar{
    display: inline-block;
    position: absolute;
    left: 7%;
    width: 10%;
    text-align: left;
}

.hover-mobile{
    display: none;
}

.marginLeft{
    margin-left: 7.5rem;
}

.hover-bar-mobile{
    display: none;

}

.span-mobile-hover{
    display: inline;
}

.span-mobile-read{
    display: none;
}
@media (max-width: 1030px){
     .hover-bar{
        display: none;
    }
    .nucalm-hover-box{
        display: none;
    }
    .hover-bar-mobile{
        display: inline-block;
        width: 85%;
        margin-left: 2rem;
        text-align: left;
        margin-right: 1rem;
    }
    .span-mobile-hover{
        display: none;
    }
    
    .span-mobile-read{
        display: inline;
    }
    /* .hover-mobile{
        display: block;
        margin-left: 3.5rem;
    }  */
}

@media (max-width: 640px){
    .nucalm-video{
        display: none;
    }
    .marginLeft{
        margin-left: 0rem;
    }

}

.question_main{
    font-size: 24px;
    font-weight: 600;
    margin-left: 75px;
    text-align: left;
    margin-right: 1rem;
}

.answer_main{
    margin-left: 7rem;
    font-weight: 300;
    font-size: 18px;
    text-align: left;
    margin-right: 2rem;
}

.subHero-header{
    font-weight: 600;
    font-size: 25px;
    /* width: 88%; */
    margin-left: 75px;
    margin-right: 2rem;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 0;
}

/* .daily_msg_box:hover + .journey_info{
    background-color: green;
} */

/* .hover-bar:hover ~ .nucalm_screens{
    filter: opacity(0.6) saturate(150%);

} */