
body{
    background-color: #222222;
    font-family: Helvetica;
    color: white;
  }
  
.answer{
    margin-left: 3rem;
    font-weight: 300;
    font-size: 20px;
    text-align: left;
}

.question{
    font-size: 30px;
    font-weight: 600;
    text-align: left;
}
.popUpContainer {
text-align: center;
width: 300px;
height: 200px;
position: relative;
/* padding-top: 20px; */
background-color: white;
display: inline-block;
border-radius: 50px;
margin: 50px;
cursor: pointer;
}

.text-overlay{
    position: absolute;
    top: 36%;
    left: 26%;
    font-weight: 600;
    font-size: 40px;
    letter-spacing: 2px;
}

.header-image{
    height: 200px;
    object-fit: cover;
    width: 300px;
    border-radius: 50px;
    filter: grayscale(0%) saturate(130%) brightness(70%);

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
}


.popUpContent{
    text-align: center;
}

.popUpImage{
    overflow: hidden;
    border-radius: 50px;
    width: 100%;
}

.popUpImageContainer{
    width: 23rem;
    border-radius: 50px;
    margin: auto;
}

.popUpContainer:hover  .header-image{
    filter: grayscale(0%) saturate(130%) brightness(40%);
}

.modal {
position: fixed;
z-index: 3;
top: 0;
width: 100%;
height: 100%;

backdrop-filter: blur(6px);
}

.modal-content {
background-color: #191616;
padding: 25px;
text-align: right;

border-radius: 25px;
border: none;

margin: auto;
position: relative;
top: 20%;

height: 60%;
width: 85%;

overflow-y: scroll;

}


.close {
color: white;
/* float: right; */
font-size: 45px;
position: fixed;
right: 6.5rem;
}

.close:hover {
color: cyan;
cursor: pointer;
}

@media(max-width: 650px){
    .close{
        right: 1.5rem;
    }
}

.audioPlayer{
margin-top: 50px;
}

.icon{
    height: 50px;
    margin-right: 10rem;
}
.popUpContentText{
    width: 75%;
    margin: auto;
    font-weight: 300;
    font-size: 18px;
    padding-top: 1rem;
}
