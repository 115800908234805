body{
  background-color: #222222;
  font-family: Helvetica;
  color: white;
  margin: 0;
}
.nucalm-img-login{
    width: 45%;
    padding-top: 70px;
    padding-bottom: 45px;
    display: block;
    margin: auto;
}    

.password{
  background-color: #222222;
  width: 45%;
  border: solid white 2px;
  height: 40px;
  padding-left: 15px;
  font-family: Helvetica;
  outline: none;
  color: white;
  font-size: 24px;
  display: block;
  margin: auto;
  font-weight: 300;
}

.termsPane{
  height: 200px;
  overflow: scroll;
  border: 1px solid #ccc;
  background: #eee;
  color: black;
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  margin-top: 20px;
}

.checkbox > span {
  color: #34495E;
  padding: 0.5rem 0.25rem;
}

.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #34495E;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #F3F3F3;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #78B0CB;
  background-color: #34495E;
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #78B0CB;
  position: absolute;
  left: 0.7rem;
  top: 0.3rem;
}

.checkbox > input:active {
  border: 2px solid #34495E;
}

.termsConditionsSubText{
  font-size: 12px;
    font-weight: 300;
    margin: auto;
    width: 75%;
    padding-top: 10px;
    padding-bottom: 10px;
}
.loginButton-login{
  border-radius: 50px;
  width: 200px;
  height: 50px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 30px;
  }

.loginText-login{
  font-size: 25px;
  font-weight: 700;
  background: -webkit-linear-gradient(#03587C, #0196C9 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  letter-spacing: 1px;
  width: 200px;
}

.containerStyle{
    text-align: center;
}

 


.loginRow{
  padding-top: 45px;
  margin: auto;
  width: 45%;
}



@media only screen and (max-width: 890px) {
  .termsAndConditions{
    display: inline-flex;
    width: 100%;
    float: left;
  }

}

.termsAndConditionsText{
  display: block;
  text-align: left;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 5px;
}

.termsAndConditionsSubText{
  display: block;
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 20px;
  text-align: left;
}

.loginButtonDiv{
  margin: auto;
}

.termsAndConditions{
  display: inline-flex;
  width: 50%;
  float: left;

}

.conditions_p{
  margin: auto;
  width: 90%;
  text-align: left;
}
