
/* body{
  background-color: #222222;
  font-family: Helvetica;
  color: white;
}

.popUpContainer {
  text-align: center;
  width: 300px;
  height: 250px;
  padding-top: 20px;
  background-color: white;
  display: inline-block;
  border-radius: 50px;
  margin: 50px;
  }

  
.popUpContainer:hover {
  background-color: grey;
}

.modal {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;

  backdrop-filter: blur(6px);
}

.modal-content {
  background-color: #036f94;
  padding: 25px;
  text-align: right;

  border-radius: 5px;
  border: none;

  margin: auto;
  position: relative;
  top: 20%;
  
  height: 60%;
  width: 85%;
} */

.close {
  color: white;
  /* float: right; */
  font-size: 25px;
}

.close:hover {
  color: cyan;
  cursor: pointer;
}

.audioPlayer{
  margin-top: 50px;
}